<div class="content-body" *ngIf="!certificate && availableFiles?.certificate && showCertificate">
    <div class="content-card border-bottom pb-32">
        <div class="card-header pt-3">
            <h3>Documento de acreditación</h3>
        </div>
        <div class="card-body" [perfectScrollbar]>
            <div class="custom-tag-container d-block">
                <div class="d-flex files-container pl-10 pr-10">
                    <div class="bg-gray-lighter rounded-10 custom-tag-item" (click)="certificateOption()">
                        <mat-icon class="icon-badge" *ngIf="withCertificate">autorenew</mat-icon>
                        <mat-icon class="icon-badge" *ngIf="!withCertificate">credit_card</mat-icon>
                        <p class=" text-gray-dark">{{titleCertificateOption}}
                        </p>
                    </div>
                    <div class="bg-gray-lighter rounded-10 custom-tag-item" (click)="certificateByPlan()"
                        *ngIf="canRedeemCertificate">
                        <mat-icon class="icon-badge">card_membership</mat-icon>
                        <p class=" text-gray-dark">Obtener certificado por suscripción
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>