import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Evaluation } from "../models/evaluation.model";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class EvaluationService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private alertService: AlertService
  ) {}

  getEvaluations(params: {}): Observable<{ data: Evaluation[] }> {
    params = { ...this.httpOptions, params };
    return this.http.get<any>(`${environment.apiURL}/evaluations`, params).pipe(
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  getEvaluation(evaluation_id: number): Observable<{ data: Evaluation }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/evaluations/${evaluation_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  userScores(user_id: number, group_id: number) {
    return this.http
      .get<any>(
        `${environment.apiURL}/evaluations/user-scores/${user_id}/${group_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  myStats(group_id: number) {
    return this.http
      .get<any>(
        `${environment.apiURL}/evaluations/my-stats/${group_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  coursesStats(data: { course_ids: number[] }) {
    return this.http
      .post<any>(
        `${environment.apiURL}/evaluations/courses-stats`,
        data,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getScores(evaluation_ids): Observable<{ data: any }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/scores?without_pagination=true&evaluation_ids=${evaluation_ids}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
}
