import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
} from "@angular/core";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { Router } from "@angular/router";
import { Course } from "../../models/course.model";
import { SubscriptionService } from "../../services/subscription.service";

@Component({
  selector: "app-sidebar-course-side",
  templateUrl: "./sidebar-course-side.component.html",
})
export class SidebarCourseSideComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  // public menuCourseItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuCourseItemsSub: Subscription;
  public layoutConf: ILayoutConf;

  @Input() menuCourseItems: any[] = [];
  @Input() course: Course = null;
  @Input() percentage: number = 0;
  // public menuCourseItems;
  public course_id: number | null = null;
  private interval: number = 3;

  constructor(
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.seeLoop();
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuCourseItemsSub) {
      this.menuCourseItemsSub.unsubscribe();
    }
  }

  seeLoop() {
    setTimeout(() => {
      this.seeSubscription();
      this.seeLoop();
    }, 180000);
  }

  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }

  goHome() {
    this.router.navigateByUrl(`/`);
  }

  seeSubscription() {
    if (!this.course.id) {
      return;
    }

    this.subscriptionService
      .seeSubscription(this.course.id, this.interval)
      .subscribe((response) => {});
  }
}
