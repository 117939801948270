import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NewMessageModalComponent } from "app/shared/modals/new-message-modal/new-message-modal.component";

@Component({
  selector: "app-teacher-card",
  templateUrl: "./teacher-card.component.html",
  styleUrls: ["./teacher-card.component.scss"],
})
export class TeacherCardComponent implements OnInit {
  @Input() teacher: any;
  @Input() courseID: number = null;
  public form: FormGroup;

  constructor(private router: Router, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      firstName: new FormControl(""),
      lastName: new FormControl(""),
    });
  }

  getName(): string {
    if (!this.teacher) {
      return ``;
    }
    return `${this.teacher.first_name} ${this.teacher.last_name}`;
  }

  openDialog(teacher) {
    let config = new MatDialogConfig();
    config = {
      height: "auto",
      width: "100vw",
      panelClass: "full-screen-modal",
      data: { user: teacher, course_id: this.courseID },
    };

    const dialogRef = this.dialog.open(NewMessageModalComponent, config);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
