import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-in-course",
  templateUrl: "./header-in-course.component.html",
  styleUrls: ["./header-in-course.component.scss"],
})
export class HeaderInCourseComponent {
  @Input() data: any;
  @Input() urlBack: string;
  @Input() urlDetail: string;
  @Input() showNavegation: boolean = true;
  @Input() previousEnabled: boolean = true;
  @Input() nextEnabled: boolean = true;

  @Output() previous = new EventEmitter<boolean>();
  @Output() next = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  goHome() {
    this.router.navigateByUrl(`${this.urlBack}`);
  }

  goDetail() {
    this.router.navigateByUrl(`${this.urlDetail}`);
  }

  handlePrevious() {
    this.previous.emit(true);
  }

  handleNext() {
    this.next.emit(true);
  }
}
