<div fxLayout="row wrap" class="tab-container">
    <div class="courses-container">
        <app-course [course]="subscription.course" *ngFor="let subscription of subscriptions"></app-course>
    </div>

    <div style="width: 100%;margin-top: 1rem;">
        <mat-paginator [length]="length" [pageSize]="per_page" [pageSizeOptions]="pageSizeOptions"
            aria-label="Select page" (page)="handlePage($event)">
        </mat-paginator>
    </div>
</div>