import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-confirm",
  template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16">{{ data.message }}</div>
    <div mat-dialog-actions>
      <button
        type="button"
        color="danger"
        mat-raised-button
        (click)="dialogRef.close(false)"
      >
        Cancelar
      </button>
      &nbsp;
      <span fxFlex></span>

      <button
        type="button"
        mat-raised-button
        color="success"
        (click)="dialogRef.close(true)"
      >
        Confirmar
      </button>
    </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
