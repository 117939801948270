import { Injectable } from "@angular/core";
import { Toaster, ToastType } from "ngx-toast-notifications";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private toaster: Toaster) {}

  public typeSuccess: ToastType = "success";
  public typeDanger: ToastType = "danger";
  public typeWarning: ToastType = "warning";
  public typeInfo: ToastType = "info";
  public typePrimary: ToastType = "primary";
  public typeSecondary: ToastType = "secondary";
  public typeDark: ToastType = "dark";
  public typeLight: ToastType = "light";

  private types: Array<ToastType> = [
    this.typeSuccess,
    this.typeDanger,
    this.typeWarning,
    this.typeInfo,
    this.typePrimary,
    this.typeSecondary,
    this.typeDark,
    this.typeLight,
  ];

  /**
   * Login
   */
  public loginError: string = "Las credenciales no son correctas";

  /**
   * Profile
   */
  public profileUpdateSuccessfully: string = "Perfil actualizado";
  public profileUpdateError: string = "No logramos actualizar el perfil";

  /**
   * Password
   */
  public passwordUpdateError: string = "Algo salió mal, vuelva  intentarlo";
  public passwordUpdateSuccessfully: string = "Contraseña cambiada con éxito";

  /**
   * Messages
   */
  public messageSendSuccessfully: string = "Mensaje enviado";

  /**
   * Files
   */
  public fileDownloadEmpty: string = "Certificado no encontrado";
  public fileDownloadSuccessfully: string = "Certificado descargado";
  public fileDownloadError: string =
    "Error en la descarga, vuelva a intentarlo";

  public fileShowSuccessfully: string = "El certificado digital encontrado";
  public fileShowEmpty: string = "El certificado digital no fue encontrado";
  public fileShowError: string =
    "Error al buscar el certificado digital, vuelva a intentarlo";

  public voucherPendingShowError: string =
    "Voucher pendiente, vuelva a intentarlo más tarde";

  showAlert(type: ToastType, text: string) {
    if (!this.validateType(type)) {
      return;
    }
    this.toaster.open({
      text: text,
      // caption: type + " notification",
      type: type,
      position: "top-center",
    });
  }

  validateType(type: ToastType) {
    return this.types.includes(type);
  }

  showErrors(error: any) {
    if (error.status !== 422) {
      return;
    }

    if (error.error && error.error.errors) {
      let message = ``;
      <any>Object.values(error.error.errors).forEach((element) => {
        message += `${element[0]} \n`;
      });

      this.showAlert(this.typeDanger, message);
      return;
    }

    if (error.error && !error.error.errors && error.error.message) {
      this.showAlert(this.typeDanger, error.error.message);
      return;
    }
  }
}
