import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { User } from "app/shared/models/user.model";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { MessageService } from "app/shared/services/message.service";
import { formatDistance, format } from "date-fns";
import { es } from "date-fns/locale";
import { Message } from "../../../shared/models/messages.model";
import { AppConfirmService } from "../../../shared/services/app-confirm/app-confirm.service";
import { UserService } from "../../../shared/services/user.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import "rxjs/add/operator/filter";

@Component({
  selector: "app-detail-messages",
  templateUrl: "./detail-messages.component.html",
  styleUrls: ["./detail-messages.component.scss"],
})
export class DetailMessagesComponent implements OnInit {
  public uuid: string | null = null;
  public messages: Message[] = [];
  public user: User;
  public form: FormGroup;
  public user_ids: number[] = [];
  public isOpen: boolean = true;
  public users: User[] = [];
  private course_id: number;
  private can_by_reply: boolean = true;
  public lengthQuill = 0;
  public lengthMax = 10000;
  private  validationType = {
    'content' : [
      Validators.required,
      Validators.maxLength(this.lengthMax),
    ]
  };

  

  constructor(
    public route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private authService: JwtAuthService,
    private confirmService: AppConfirmService,
    private userService: UserService
  ) {
    this.user = this.authService.getUser();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.uuid = route.snapshot.params.uuid;
        this.course_id = route.snapshot.params.course_id;
        this.getHistory();
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams
      .filter((params) => params.can_by_reply)
      .subscribe((params) => {
        this.can_by_reply =
          params.can_by_reply == "true" || params.can_by_reply === true;
      });

    this.form = new FormGroup({
      content: new FormControl("", [
        Validators.required,
        Validators.maxLength(10000),
      ]),
    });
  }

  get canByReply() {
    return this.can_by_reply;
  }

  changeUuid($event: any): void {
    if (this.uuid !== $event) {
      this.router.navigate([`messages/${$event}`]);
    }
  }

  getHistory() {
    this.messageService.getHistory(this.uuid).subscribe((response) => {
      this.messages = response.data.reverse();

      this.messages = response.data;
      this.messages.forEach((message) => {
        if (message.send_by.id === this.user.id) {
          message.own = true;
          message.user_ids.forEach((user_id) => {
            user_id = Number(user_id);
            if (user_id !== this.user.id) {
              this.user_ids.push(user_id);
            }
          });
        } else {
          this.user_ids.push(message.send_by.id);
          message.own = false;
        }
      });
      this.user_ids = this.user_ids.filter((element, index) => {
        return this.user_ids.indexOf(element) === index;
      });

      this.getParticipants();
    });
  }

  getParticipants() {
    this.userService.getParticipants(this.user_ids).subscribe((response) => {
      this.users = response.data;
    });
  }

  getTimeFormat(message: Message): string {
    let time = formatDistance(new Date(message.published_at), new Date(), {
      addSuffix: true,
      locale: es,
    });

    return time;
  }

  getTime(message: Message): string {
    return format(new Date(message.published_at), "dd/MM/yyyy HH:mm a");
  }

  getContent(message: Message) {
    return message.content.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }

  getSendBy(message: Message): string {
    let name = `${message.send_by.first_name} ${message.send_by.last_name}`;

    return name;
  }

  get content() {
    return this.form.get("content");
  }

  onSubmit() {
    
    this.addValidators(this.form);

    if (this.form.invalid) {
      this.content.markAsDirty();
      this.content.markAsTouched();
      return;
    }
    let user_ids = this.user_ids.filter((element, index) => {
      return this.user_ids.indexOf(element) === index;
    });

    this.confirmService
      .confirm({ message: `¿Desea enviar el mensaje?` })
      .subscribe((response) => {
        if (response) {
          let data = {
            content: this.content.value,
            user_ids: user_ids,
            uuid: this.uuid,
            course_id: this.course_id,
            to_support: false
          };

          if (this.uuid) {
            delete data.course_id;
          }

          this.messageService.storeMessage(data).subscribe((response) => {
            this.form.reset();          
            this.removeValidators(this.form);
            setTimeout(() => {
              this.getHistory();
            }, 800);
          });
        }
      });
  }

  removeValidators(form: FormGroup) {
    for (const key in form.controls) {
        form.get(key).clearValidators();
        form.get(key).updateValueAndValidity();
    }
  }

  addValidators(form: FormGroup) {
    for (const key in form.controls) {
        form.get(key).setValidators(this.validationType[key]);
        form.get(key).updateValueAndValidity();
    }
  }

  get hasCourse() {
    return this.course_id ? true : false;
  }

  textChanged($event) {
    this.lengthQuill = $event.editor.getLength() - 1;
    if ($event.editor.getLength() > this.lengthMax) {
      $event.editor.deleteText(this.lengthMax, $event.editor.getLength());
    }
  }

  backToCourse() {
    this.router.navigateByUrl(`/courses/${this.course_id}/messages`);
  }

  toggleHistory(): void {
    this.isOpen = !this.isOpen;
  }
}
