import { Component, OnInit, Input } from "@angular/core";
import { Course } from "app/shared/models/course.model";
import { AlertService } from "app/shared/services/alert.service";

@Component({
  selector: "app-sidenav-course",
  templateUrl: "./sidenav-course.template.html",
})
export class SidenavCourseComponent {
  @Input("items") public menuItems: any[] = [];
  @Input("hasIconMenu") public hasIconTypeMenuItem: boolean;
  @Input("iconMenuTitle") public iconTypeMenuTitle: string;
  @Input() course: Course;
  @Input() percentage: number = 0;
  private noContentMessage: string = "Este curso no tiene enlace registrado";

  constructor(private alertService: AlertService) {}
  ngOnInit() {
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: "ITEM",
      type: "dropDown",
      tooltip: "Item",
      icon: "done",
      state: "material",
      sub: [
        { name: "SUBITEM", state: "cards" },
        { name: "SUBITEM", state: "buttons" },
      ],
    });
  }

  handleExternalLink(item: any) {
    if (!item.state) {
      this.alertService.showAlert(this.alertService.typeInfo, this.noContentMessage)
      return
    }
    window.open(item.state, '_blank');
  }
}
