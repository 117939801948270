import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CourseSubscription } from "app/shared/models/course-subscription";
import { LearningPackage } from "app/shared/models/learning-package.model";
import { Payment } from "app/shared/models/payment.model";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { PaymentService } from "app/shared/services/payment.service";
import { SubscriptionService } from "app/shared/services/subscription.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-certificate-section",
  templateUrl: "./certificate-section.component.html",
  styleUrls: ["./certificate-section.component.scss"],
})
export class CertificateSectionComponent implements OnInit {
  @Input() subscription: CourseSubscription = null;
  @Input() withCertificate: boolean = false;
  @Input() certificate: any = null;
  @Input() availableFiles: {
    certificate: boolean;
    record: boolean;
  } = {
    certificate: false,
    record: false,
  };
  @Input() canRedeemCertificate: boolean = false;
  @Output() reloadSubscription = new EventEmitter<boolean>();
  private loadingFile: boolean = false;
  private waitingPayment: Payment = null;
  private origin: string = null;

  constructor(
    private subscriptionService: SubscriptionService,
    private paymentService: PaymentService,
    private appConfirmService: AppConfirmService,
    public route: ActivatedRoute
  ) {
    this.origin =
      route.snapshot && route.snapshot.params
        ? route.snapshot.params.origin
        : null;
  }

  ngOnInit(): void {}

  get showCertificate(): boolean {
    return this.origin == undefined || !!this.origin;
  }

  get titleCertificateOption() {
    let finished_recourd = `Generar constancia de finalización`;
    if (!this.withCertificate) return `Comprar certificado`;
    if (this.origin && this.origin === "path") return finished_recourd;
    if (this.subscription.access_by_learning_package) {
      let learning_package: LearningPackage =
        this.subscription.learning_package;
      if (!!learning_package && learning_package.type === "path") {
        return finished_recourd;
      }
    }
    return `Generar certificado`;
  }

  certificateOption() {
    if (this.withCertificate) {
      if (this.origin && this.origin === "path") return this.generateRecord();

      if (this.subscription.access_by_learning_package) {
        let learning_package: LearningPackage =
          this.subscription.learning_package;
        if (!!learning_package && learning_package.type === "path") {
          return this.generateRecord();
        }
      }

      this.generateCertificate();
      return;
    }

    this.generateCertificatePayment();
  }

  generateRecord() {
    if (this.loadingFile) {
      return;
    }

    this.loadingFile = true;
    this.subscriptionService.generateMyRecord(this.subscription.id).subscribe(
      (response) => {
        this.certificate = response.data;
        this.downloadCertificatePDF();
        this.loadingFile = false;
      },
      (error) => {
        console.log(error);
        this.loadingFile = false;
      }
    );
  }

  generateCertificate() {
    if (this.loadingFile) {
      return;
    }

    this.loadingFile = true;
    this.subscriptionService
      .generateMyCertificate(this.subscription.id)
      .subscribe(
        (response) => {
          this.certificate = response.data;
          this.downloadCertificatePDF();
          this.loadingFile = false;
        },
        (error) => {
          console.log(error);
          this.loadingFile = false;
        }
      );
  }

  generateCertificatePayment() {
    this.subscriptionService
      .generateCertificatePayment(this.subscription.id)
      .subscribe((response) => {
        if (response.payment && response.payment.payment_code) {
          this.availableCheckout(
            response.payment,
            response.payment.payment_code
          );
        }
      });
  }

  downloadCertificatePDF() {
    if (this.loadingFile) {
      return;
    }

    this.loadingFile = true;
    this.subscriptionService
      .downloadFileByHash(this.certificate.hash)
      .subscribe(
        (response) => {
          let data = response.data;
          const linkSource = `data:application/pdf;base64,${data.file}`;
          const downloadLink = document.createElement("a");
          const fileName = `${data.filename}.pdf`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.loadingFile = false;
        },
        (error) => {
          console.log("getPDF error: ", error);
          this.loadingFile = false;
        }
      );
  }

  availableCheckout(payment, code) {
    this.waitingPayment = payment;

    this.goExternalPayment(code);
    this.checkPaymentStatus();
  }

  checkPaymentStatus() {
    if (!this.waitingPayment) {
      return;
    }

    this.paymentService
      .showPayment(this.waitingPayment.id)
      .subscribe((response) => {
        if (response.data.status === "confirmed") {
          this.reloadSubscription.emit(true);
        } else {
          setTimeout(() => {
            this.checkPaymentStatus();
          }, 3000);
        }
      });
  }

  certificateByPlan() {
    this.subscriptionService
      .redeemCertificate(this.subscription.id)
      .subscribe((response) => {
        this.reloadSubscription.emit(true);
      });
  }

  goExternalPayment(code) {
    let url = `${environment.campusPaymentUrl}?CodPago=${code}`;
    this.appConfirmService.popupCenter(url, "ulearning", 700, 600);
  }
}
