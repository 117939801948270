import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "currencyCustom",
})
export class CurrencyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === 0) {
      return "Gratis";
    }
    return `S/ ${Number(value.toFixed(2))}`;
  }
}
