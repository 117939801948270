import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { User } from "../models/user.model";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { LocalStoreService } from "./local-store.service";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private alertService: AlertService
  ) {}

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  getParticipants(user_ids: number[]): Observable<{ data: User[] }> {
    let url = `${environment.apiURL}/messages-participants?ids=${user_ids}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getCountries(): Observable<{
    data: any[];
  }> {
    let url = `${environment.apiURL}/countries`;

    return this.http.get<any>(url);
  }

  getUsers(params: any = {}): Observable<{ data: User[] }> {
    if (!params.course_id) {
      delete params.course_id
    }

    if (!params.search_receivers) {
      delete params.search_receivers
    }
    
    let url = `${environment.apiURL}/users`;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getJwtToken()}`,
      }),
      params: params,
    });
  }

  storeAvatar(avatar) {
    return this.http
      .post(
        `${environment.apiURL}/users/avatar`,
        { avatar: avatar },
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }
}
