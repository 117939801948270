import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { DashboardComponent } from "./views/home/home.component";
import { CourseLayoutComponent } from './shared/components/layouts/course-layout/course-layout.component';
import { DetailMessagesComponent } from "./views/messages/detail-messages/detail-messages.component";

export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () =>
          import("./views/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "certifications",
    loadChildren: () =>
      import("./views/guest/guest.module").then((m) => m.GuestModule),
    data: { title: "Invitado" },
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "home",
        redirectTo: "courses",
      },
      {
        path: "messages",
        loadChildren: () =>
          import("./views/messages/messages.module").then(
            (m) => m.MessagesModule
          ),
      },
      {
        path: "messages/:uuid",
        component: DetailMessagesComponent,
      },
      {
        path: "payments",
        loadChildren: () =>
          import("./views/payments/payments.module").then(
            (m) => m.PaymentsModule
          ),
        data: { title: "Cursos" },
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then((m) => m.ProfileModule),
        data: { title: "Perfil" },
      },
    ],
  },
  {
    path: "",
    component: CourseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "courses",
        loadChildren: () =>
          import("./views/courses/courses.module").then((m) => m.CoursesModule),
        data: { title: "Cursos" },
      },
    ]
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
