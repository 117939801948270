<div class="course custom-tag-item" (click)="goCourse(course)">
    <div class="course-info">
        <div class="category-container">
            <mat-chip class="category-chip" cdkDrag>
                {{course?.category?.name}}
            </mat-chip>        
        </div>
        <div class="title-container">
            <h2>{{ course?.title }}</h2>
        </div>

        <div class="bottom-container">
            <div class="progress-container">
                <div class="progress-label">            
                    <span class="ml-auto">
                        {{ course?.percentage }} %
                    </span>
                </div>
                <div class="progress-bar">
                    <mat-progress-bar mode="determinate" [value]="course?.percentage" class="mb-3" color="green">
                    </mat-progress-bar>
                </div>
            </div>
            <div class="course-preview">
                <button mat-stroked-button color="success" type="button" class="custom-small-button">
                    {{ course?.percentage == 100 ? 'Concluido' : "Continuar" }}       
                </button>
            </div>
        </div>
    </div>
</div>