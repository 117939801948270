import { Component, Input, OnInit } from '@angular/core';
import { CourseSubscription } from 'app/shared/models/course-subscription';

@Component({
  selector: 'app-list-card-learning-package',
  templateUrl: './list-card-learning-package.component.html',
  styleUrls: ['./list-card-learning-package.component.scss']
})
export class ListCardLearningPackageComponent implements OnInit {
  @Input() subscriptions: CourseSubscription[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
