import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  ResolveStart,
  ResolveEnd,
  ActivatedRoute,
} from "@angular/router";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ThemeService } from "../../../services/theme.service";
import { LayoutService } from "../../../services/layout.service";
import { filter } from "rxjs/operators";
import { JwtAuthService } from "../../../services/auth/jwt-auth.service";
import { SubscriptionService } from "../../../services/subscription.service";
import { CourseSubscription } from "app/shared/models/course-subscription";
import { Course } from "app/shared/models/course.model";
import { CourseService } from "../../../services/course.service";
import { Topic } from "app/shared/models/topic";
import { EvaluationService } from "../../../services/evaluation.service";
import { LearningPackage } from "app/shared/models/learning-package.model";

@Component({
  selector: "app-course-layout",
  templateUrl: "./course-layout.template.html",
})
export class CourseLayoutComponent implements OnInit, AfterViewInit {
  public isModuleLoading: Boolean = false;
  private moduleLoaderSub: Subscription;
  private layoutConfSub: Subscription;
  private routerEventSub: Subscription;

  public scrollConfig = {};
  public layoutConf: any = {};
  public course_id: number | null = null;
  public learning_package_id: number | null = null;
  public group_id: number | null = null;
  public courseContainerClasses: any = {};
  public subscription: CourseSubscription;
  public course: Course;
  public menuCourseItems: any[] = [];
  public topicsData: Topic[] = [];
  public topics: any;
  public percentage: number = 0;
  public learning_package: LearningPackage;
  private course_ids: number[] = [];
  private course_ids_with_required: {
    course_id: number;
    is_required: boolean;
    self_study_hour: number;
  }[] = [];

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    public themeService: ThemeService,
    private layout: LayoutService,
    private cdr: ChangeDetectorRef,
    private jwtAuth: JwtAuthService,
    private subscriptionService: SubscriptionService,
    private courseService: CourseService,
    private evaluationService: EvaluationService
  ) {
    // Check Auth Token is valid
    // this.jwtAuth.checkTokenIsValid().subscribe();

    // Close sidenav after route change in mobile
    this.routerEventSub = router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange: NavigationEnd) => {
        this.route.queryParams.subscribe((queryParams) => {
          let origin_path = false;

          if (queryParams.origin && queryParams.origin === "path") {
            origin_path = true;
          }
          let params = { origin: origin_path ? "path" : "" };

          let data = routeChange.url.split("?")[0].split("/").filter(Boolean);
          if (data.length > 1 && data[0] === "courses") {
            if (data[2] === "learning_package") {
              this.learning_package_id = Number(data[1]);
              this.menuCourseItems = [
                {
                  id: "courses",
                  name: "Cursos",
                  type: "dropDown",
                  icon: "event_note",
                  state: ``,
                  sub: [],
                  queryParams: {},
                },
              ];

              this.subscriptionService
                .getSubscriptionByLearningPackage(this.learning_package_id)
                .subscribe((response: { data: CourseSubscription }) => {
                  this.subscription = response.data;
                  this.learning_package = response.data.learning_package;
                  this.percentage = 0;
                  this.course_ids = [];
                  this.course_ids_with_required = [];

                  this.learning_package.items.forEach((item) => {
                    if (
                      this.subscription.course_ids_by_learning_package.includes(
                        item.course_id
                      )
                    ) {
                      this.course_ids.push(item.course_id);
                      this.course_ids_with_required.push({
                        course_id: item.course_id,
                        is_required: item.is_required,
                        self_study_hour: item.course.self_study_hour || 0,
                      });
                    }
                  });

                  this.menuCourseItems.forEach((item) => {
                    if (
                      item.id === "contenido" &&
                      this.course.external_link != null
                    ) {
                      item.type = "externalLink";
                      item.state = this.course.external_link;
                      item.icon = "launch";
                    }
                  });

                  let navTopics: any[] = [];

                  let sub = [];

                  let origin = this.learning_package.type;
                  this.learning_package.items.forEach((item) => {
                    if (
                      this.subscription.course_ids_by_learning_package.includes(
                        item.course_id
                      )
                    ) {
                      sub.push({
                        name: item.course.title,
                        state: `/courses/${item.course_id}`,
                        queryParams: params,
                      });
                    }
                  });

                  this.menuCourseItems.forEach((item) => {
                    if (item.icon === "event_note") {
                      item.sub = sub;
                    }
                  });

                  this.getCoursesAdvances();
                  //this.getMyStats();
                });

              this.layout.adjustLayout({ route: routeChange.url });
              this.scrollToTop();
              return;
            }
            this.course_id = Number(data[1]);
            this.menuCourseItems = [
              {
                id: "info",
                name: "Información del curso",
                type: "link",
                icon: "library_books",
                state: `/courses/${this.course_id}`,
                queryParams: params,
              },
              {
                id: "contenido",
                name: "Contenido",
                type: "dropDown",
                icon: "event_note",
                state: `/courses/${this.course_id}/topics`,
                queryParams: params,
                sub: [],
              },
              {
                id: "mensajeria",
                name: "Mensajería",
                type: "link",
                icon: "speaker_notes",
                state: `/courses/${this.course_id}/messages`,
                queryParams: params,
              },
            ];

            if (!!this.course_id) {
              this.subscriptionService
                .getSubscriptionByCourse(this.course_id)
                .subscribe((response: { data: CourseSubscription }) => {
                  this.subscription = response.data;
                  this.course = response.data.course;

                  this.group_id = this.subscription.group_id;
                  this.percentage = 0;
                  this.course.evaluationStats = null;

                  this.menuCourseItems.forEach((item) => {
                    if (
                      item.id === "contenido" &&
                      this.course.external_link != null
                    ) {
                      item.type = "externalLink";
                      item.state = this.course.external_link;
                      item.icon = "launch";
                    }
                  });

                  this.menuCourseItems.push({
                    name: "Evaluaciones",
                    type: "link",
                    icon: "description",
                    state: `/courses/${this.course_id}/groups/${this.group_id}/evaluations`,
                    queryParams: params,
                  });

                  this.course_ids = [this.course_id];
                  this.getCoursesAdvances();
                  this.getMyStats();
                  this.courseService
                    .getTopics(this.course_id, "forums")
                    .subscribe((response) => {
                      this.topics = response.data;
                      this.refreshTopicData(params);
                    });
                });
            }
          }
        });

        this.layout.adjustLayout({ route: routeChange.url });
        this.scrollToTop();
      });

    // Translator init
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
  }

  ngOnInit() {
    // this.layoutConf = this.layout.layoutConf;
    this.layoutConfSub = this.layout.layoutConf$.subscribe((layoutConf) => {
      this.layoutConf = layoutConf;
      this.courseContainerClasses = this.updateAdminContainerClasses(
        this.layoutConf
      );
      this.cdr.markForCheck();
    });

    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe((event) => {
      if (
        event instanceof RouteConfigLoadStart ||
        event instanceof ResolveStart
      ) {
        this.isModuleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });

    if (!this.course_id) {
      return;
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.layout.adjustLayout(event);
  }

  refreshTopicData(params: {}): void {
    let navTopics: any[] = [];
    this.topics.forEach((topic: Topic) => {
      let sub = [];
      if (!topic.parent_id) {
        this.topics.forEach((children: Topic) => {
          children.completed = false;

          if (children.parent_id === topic.id) {
            sub.push({
              name: children.title,
              state: `/courses/${this.course_id}/topics/${children.id}`,
              queryParams: params,
            });

            children.forums.forEach((forum: any) => {
              sub.push({
                name: `(Foro) ${forum.subject}`,
                state: `/courses/${this.course_id}/forums/${forum?.uuid}`,
                queryParams: params,
              });
            });
          }
        });

        navTopics.push({
          name: topic.title,
          state: topic.id,
          type: "dropDown",
          sub: sub,
        });
      }
    });

    this.menuCourseItems.forEach((item) => {
      if (item.icon === "event_note") {
        item.sub = navTopics;
      }
    });
  }

  getCoursesAdvances() {
    if (this.course_ids.length === 0) return;

    let is_learning_package =
      !!this.learning_package &&
      !!this.learning_package.id &&
      this.subscription.classification !== "course";
    let data = {
      course_ids: this.course_ids.join(),
      course_ids_with_required: this.course_ids_with_required,
    };
    if (is_learning_package) {
      data["with_summary"] = true;
      data["required_hours"] = this.learning_package.required_hours;
    }
    this.courseService.getCoursesAdvances(data).subscribe((response) => {
      if (is_learning_package) {
        let value = response.summary;
        this.percentage = value > 100 ? 100 : value;
        return;
      }

      let percentage = 0;
      response.data.forEach((item) => {
        percentage += item.percentage < 100 ? item.percentage : 100;
      });
      this.percentage = percentage / this.course_ids.length;
    });
  }

  getMyStats() {
    if (!this.group_id) return;
    this.evaluationService.myStats(this.group_id).subscribe((response) => {
      this.course.evaluationStats = response.data;
    });
  }

  ngAfterViewInit() {}

  scrollToTop() {
    if (document) {
      setTimeout(() => {
        let element;
        if (this.layoutConf.topbarFixed) {
          element = <HTMLElement>(
            document.querySelector("#rightside-content-hold")
          );
        } else {
          element = <HTMLElement>document.querySelector("#main-content-wrap");
        }
        element.scrollTop = 0;
      });
    }
  }
  ngOnDestroy() {
    if (this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
    if (this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }
  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }

  sidebarMouseenter(e) {
    if (this.layoutConf.sidebarStyle === "compact") {
      this.layout.publishLayoutChange(
        { sidebarStyle: "full" },
        { transitionClass: true }
      );
    }
  }

  sidebarMouseleave(e) {
    if (
      this.layoutConf.sidebarStyle === "full" &&
      this.layoutConf.sidebarCompactToggle
    ) {
      this.layout.publishLayoutChange(
        { sidebarStyle: "compact" },
        { transitionClass: true }
      );
    }
  }

  updateAdminContainerClasses(layoutConf) {
    return {
      "navigation-top": layoutConf.navigationPos === "top",
      "sidebar-full": layoutConf.sidebarStyle === "full",
      "sidebar-compact":
        layoutConf.sidebarStyle === "compact" &&
        layoutConf.navigationPos === "side",
      "compact-toggle-active": layoutConf.sidebarCompactToggle,
      "sidebar-compact-big":
        layoutConf.sidebarStyle === "compact-big" &&
        layoutConf.navigationPos === "side",
      "sidebar-opened":
        layoutConf.sidebarStyle !== "closed" &&
        layoutConf.navigationPos === "side",
      "sidebar-closed": layoutConf.sidebarStyle === "closed",
      "fixed-topbar":
        layoutConf.topbarFixed && layoutConf.navigationPos === "side",
    };
  }
}
