import { Pipe, PipeTransform } from "@angular/core";

const subtypes = [
  {
    key: "tutor",
    value: "Tutor",
  },
  {
    key: "instructor",
    value: "Instructor",
  },
  {
    key: "tutor",
    value: "Tutor",
  },
  {
    key: "facilitator",
    value: "Facilitador",
  },
  {
    key: "academic_tutor",
    value: "Tutor académico",
  },
  {
    key: "umbrella_tutor",
    value: "Tutor sombrilla",
  },
  {
    key: "administrative_tutor",
    value: "Tutor administrativo",
  },
];

@Pipe({
  name: "memberSubtype",
})
export class MemberSubtypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let index = subtypes.findIndex((subtype) => {
      return subtype.key === value;
    });

    if (index > -1) {
      return subtypes[index].value;
    }
    return "Docente principal";
  }
}
