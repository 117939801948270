<div class="teacher-card">
    <div class="card-image">
        <span></span>
        <img src="assets/images/avatars/001-man.svg" />
        <span class="per-name">{{ getName() }}</span>
    </div>

    <div class="card-content">
        <span class="per-position">{{ teacher?.subtype | memberSubtype }}</span>
        <!-- <div class="card-text">
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span>
                </div> -->
        <div class="social-icons">
            <!-- <i class="fab fa-whatsapp" title="WhatsApp"></i> -->
            <div class="card-btn">
                <button type="button" title="Contactar" (click)="openDialog(teacher)">Contactar</button>
            </div>
        </div>
    </div>
</div>