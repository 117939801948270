<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">

            <!-- App Logo -->
            <div class="branding">
                <!-- <img src="assets/images/logo.png" alt="" class="app-logo"> -->
                <span class="app-logo-text" style="cursor: pointer; font-weight: bold;"
                    (click)="goHome()">U-Learning</span>

                <!-- <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span> -->
            </div>

            <!-- Sidebar user -->
            <div class="app-user">
                <!-- <div class="app-user-photo">
                    <img src="assets/images/face-7.jpg" class="mat-elevation-z1" alt="">
                </div> -->
                <span class="app-user-name mb-8">
                    <!-- <mat-icon class="icon-xs text-muted">lock</mat-icon> -->
                    {{jwtAuth?.user?.displayName}}
                </span>
            </div>
            <!-- Navigation -->
            <app-sidenav-course [course]="course" [items]="menuCourseItems" [hasIconMenu]="hasIconTypeMenuItem"
                [iconMenuTitle]="iconTypeMenuTitle" [percentage]="percentage">
            </app-sidenav-course>
        </div>
    </div>
</div>