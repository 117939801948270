<div class="content-body">
    <div class="content-card border-bottom pb-32">
        <div class="card-header pt-3">
            <h3>{{title}}</h3>
        </div>
        <div class="card-body" [perfectScrollbar]>
            <div class="p-0">
                <div [innerHTML]="description">
                </div>
            </div>
        </div>
    </div>
</div>