<h3 mat-dialog-title></h3>
<mat-dialog-content class="mat-typography">
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ data.payable | currencyCustom }}

                <button mat-icon-button color="danger" class="mr-8" *ngIf="hasExternalCode || hasQuota" class="ml-auto"
                    (click)="deletePayment()">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="border-bottom pb-16 mb-16" *ngFor="let item of data.items">
            <div fxLyout="row wrap" class="text-blue font-weight-bold">
                <span>{{ item.title }}</span>
                <span fxFlex></span>
                <span>{{ item.payable | currencyCustom }}</span>
            </div>
            <!-- <div fxLyout="row wrap" class="text-muted">
                <small>24 hour change</small>
                <span fxFlex></span>
                <small class="text-green">530(+3.73%)</small>
            </div> -->
        </div>
    </mat-expansion-panel>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="mr-auto">Cerrar</button>
    <button [disabled]="waitingPayment" mat-button color="success" class="ml-auto" (click)="goExternalPayment()"
        *ngIf="showPaymentButton">
        Ir a pagar
    </button>
</mat-dialog-actions>