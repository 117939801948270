<div class="container">
    <div
        style="display: flex; justify-content: space-between;align-items:center;margin-right: 1.5rem; margin-top: 1.5rem">
        <h2 class="tituloseccion">Continúa aprendiendo</h2>
        <a class="decoration-none text-blue" [routerLink]="['/courses']">Ver todos</a>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="33.33" fxFlex.sm="100" *ngFor="let course of courses">
            <app-course [course]="course"></app-course>
        </div>
    </div>
</div>