import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap, debounce, debounceTime } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CourseAdvance } from "../models/course-advance";
import { Topic } from "../models/topic";
import { Course } from "../models/course.model";
import { CourseSubscription } from "../models/course-subscription";
import { Forum } from "../models/messages.model";

@Injectable({
  providedIn: "root",
})
export class CourseService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar
  ) {}

  public getCourses(cantElementos = 0, inicio = 0) {
    // this.loader.open()
    return this.http
      .get(`${environment.apiURL}/courses`, this.httpOptions)
      .pipe(
        map((res: any) =>
          cantElementos == 0 ? res.data : res.data.slice(inicio, cantElementos)
        ),
        //  tap( _ => this.loader.close()),

        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getCourse(course_id: number) {
    // this.loader.open();
    return this.http
      .get(`${environment.apiURL}/courses/${course_id}`, this.httpOptions)
      .pipe(
        map((res: any) => res.data),
        // tap((_) => this.loader.close()),

        catchError((error) => {
          this.loader.close();
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getSubscriptions(
    filter: {
      is_finished: boolean;
      classification: string;
      without_pagination: boolean;
      includes: string;
      not_in_learning_package?: boolean;
    },
    meta: {
      per_page: number;
      page: number;
    }
  ): Observable<{ data: CourseSubscription[]; meta: any }> {
    let url = `${environment.apiURL}/subscriptions?per_page=${meta.per_page}&page=${meta.page}&classification=${filter.classification}&includes=${filter.includes}`;
    if (filter.classification === "course") {
      url = `${url}&is_finished=${filter.is_finished}`;
    }
    if (filter.without_pagination) {
      url = `${url}&without_pagination=true`;
    }
    if (filter.not_in_learning_package) {
      url = `${url}&not_in_learning_package=true`;
    }
    return this.http.get<any>(url, this.httpOptions);
  }

  getProgress(course_id: number): Observable<CourseAdvance> {
    let url = `${environment.apiURL}/courses/${course_id}/my-progress`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getTopics(
    course_id: number,
    includes: string = ""
  ): Observable<{ data: Topic[]; links: any; meta: any }> {
    let url = `${environment.apiURL}/topics?course_id=${course_id}&includes=${includes}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getCourseForums(course_id: number): Observable<{ data: Forum[] }> {
    let url = `${environment.apiURL}/topics-forums/courses/${course_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getTopic(topic_id: number): Observable<{ data: Topic }> {
    let url = `${environment.apiURL}/topics/${topic_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getFiles(topic_id: number): Observable<{ data: Topic }> {
    let url = `${environment.apiURL}/topics/${topic_id}/files`;

    return this.http.get<any>(url, this.httpOptions);
  }

  storeAdvance(data: { topic_id: number }) {
    let url = `${environment.apiURL}/course-advances`;

    return this.http.post<any>(url, data, this.httpOptions);
  }

  destroyProgress(topic_id: number) {
    let url = `${environment.apiURL}/course-progress/${topic_id}`;

    return this.http.delete<any>(url, this.httpOptions);
  }

  getCoursesInProgress(): Observable<{ data: Course[] }> {
    let url = `${environment.apiURL}/courses-in-progress`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getCoursesAdvances(data: any): Observable<{ data: any; summary?: any }> {
    let url = `${environment.apiURL}/courses-advances`;

    return this.http.post<any>(url, data, this.httpOptions);
  }

  getLastTopicView(course_id: Number): Observable<{ data: any }> {
    let url = `${environment.apiURL}/courses/${course_id}/last-view`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  getForums(topic_id: number): Observable<{ data: any[] }> {
    let url = `${environment.apiURL}/topics-forums/topics/${topic_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getHistoryForum(uuid: string): Observable<{ data: any[] }> {
    let url = `${environment.apiURL}/topics-forums/${uuid}/history`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getOrders(
    topic_id: number
  ): Observable<{ data: { previous: Topic | null; next: Topic | null } }> {
    let url = `${environment.apiURL}/topics-orders/${topic_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }
}
