import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { Conversation } from "app/shared/models/conversation.model";
import { User } from "app/shared/models/user.model";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user.service";
import { JwtAuthService } from "../../services/auth/jwt-auth.service";
import { format } from "date-fns";

@Component({
  selector: "app-conversation",
  templateUrl: "./conversation.component.html",
  styleUrls: ["./conversation.component.scss"],
})
export class ConversationComponent implements OnInit, OnDestroy {
  routeParts: any[];
  routerEventSub: Subscription;

  @Input() conversation: Conversation;
  @Input() receivers: User[] = [];

  private authUser: User;
  public receiverUsers: User[] = [];

  constructor(private router: Router, private jwtAuth: JwtAuthService) {
    this.authUser = this.jwtAuth.getUser();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    let receivers: User[] = changes.receivers.currentValue;

    receivers.forEach((receiver) => {
      let user_ids = [];
      this.message.user_ids.forEach((id) => {
        user_ids.push(Number(id));
      });
      if (user_ids.includes(receiver.id)) {
        if (
          this.receiverUsers.findIndex((user) => receiver.id === user.id) === -1
        ) {
          this.receiverUsers.push(receiver);
        }
      }

      if (this.authUser && user_ids.includes(this.authUser.id)) {
        if (
          this.receiverUsers.findIndex(
            (user) => this.authUser.id === user.id
          ) === -1
        ) {
          this.receiverUsers.push(this.authUser);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  goConversation = (conversation: any): void => {
    let url = `/courses/${conversation.course_id}/messages/${conversation.uuid}`
    if(!conversation.course_id) {
      url = `/messages/${conversation.uuid}`
    }
    this.router.navigate(
      [url],
      { queryParams: { can_by_reply: conversation.can_by_reply } }
    );
  };

  get message() {
    return this.conversation.firstMessage;
  }

  getTime() {
    if (!this.message.published_at){
      return;
    }
    return format(new Date(this.message.published_at), "dd/MM/yyyy HH:mm a");
  }

  // get receiver() {
  //   let receiverUsers = [];
  //   this.receivers.forEach((receiver) => {
  //     if (this.message.user_ids.includes(receiver.id)) {
  //       receiverUsers.push(receiver);
  //     }
  //   });
  //   return this.message ? this.message.classification : "";
  // }

  get content() {
    return this.message ? this.message.content : "";
  }
}
