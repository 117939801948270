import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertService } from "app/shared/services/alert.service";
import { FeedbackService } from "app/shared/services/feedback.service";
import { AppConfirmService } from "../../services/app-confirm/app-confirm.service";

@Component({
  selector: "edit-feedback-modal",
  templateUrl: "edit-feedback-modal.component.html",
  styleUrls: ["edit-feedback-modal.component.scss"],
})
export class EditFeedbackModalComponent implements OnInit {
  public form: FormGroup;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;
  public rating: number = 5;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditFeedbackModalComponent>,
    private confirmService: AppConfirmService,
    private alertService: AlertService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      comment: new FormControl("", [
        Validators.required,
        Validators.maxLength(5000),
      ]),
    });
  }

  get comment() {
    return this.form.get("comment");
  }

  onSubmit() {
    if (this.form.invalid) {
      this.comment.markAsDirty();
      this.comment.markAsTouched();
      return;
    }

    if (this.rating < 1) {
      this.rating = 1;
    }

    this.confirmService
      .confirm({ message: `¿Desea registrar su valoración?` })
      .subscribe((response) => {
        if (response) {
          this.feedbackService
            .storeFeedback({
              course_id: this.data.id,
              comment: this.comment.value,
              rating: this.rating,
            })
            .subscribe((response) => {
              this.form.reset();
              this.dialogRef.close(true);
            });
        }
      });
  }

  onRatingChange($event) {
    this.rating = $event.rating;
  }
}
