<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title>¡Tu comentario es valioso!</h3>
    <mat-dialog-content class="mat-typography">
        <mat-card-title class="">
            <mat-card-content>
                <div class="rating-container" fxLayout="row wrap" fxLayout.lt-md="column">
                    <div fxLayout="row" fxLayout-align="end">
                        <star-rating [rating]="rating" [starType]="'svg'" [readOnly]="false" [staticColor]="'none'"
                            (ratingChange)="onRatingChange($event)">
                        </star-rating>
                    </div>
                </div>
            </mat-card-content>
        </mat-card-title>

        <mat-form-field class="w-100">
            <mat-label>Comentario</mat-label>
            <textarea placeholder="Agregue un comentario..." matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3" class="w-100" formControlName="comment"></textarea>
            <mat-error *ngIf="comment.hasError('required')">
                Complete su comentario
            </mat-error>
            <mat-error *ngIf="comment.hasError('maxLength')">
                El contenido no puede tener más de 5,000 caracteres
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close class="mr-auto btn-outline-seconday">Cerrar</button>
        <button mat-stroked-button color="success" type="submit">Enviar</button>
    </mat-dialog-actions>
</form>