import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay, tap } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError } from "rxjs";
import { environment } from "environments/environment";
import { AlertService } from "../alert.service";

// ================= only for demo purpose ===========

const DEMO_USER: User = {
  id: 1,
  first_name: "Jhon",
  role: "student",
};
// ================= you will get those data from server =======

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "USER";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    this.route.queryParams.subscribe(
      (params) => (this.return = params["return"] || "/")
    );
  }

  public signin(username, password) {
    this.signingIn = true;
    return this.http
      .post(`${environment.apiURL}/login`, { email: username, password })
      .pipe(
        map((res: any) => {
          let token = res.token;
          this.setToken(token, !!token);
          this.getProfile(token).subscribe((e: any) => {
            this.setUserAndToken(token, e.data, !!token);
            this.signingIn = false;
            return token;
          });
        }),
        catchError((error) => {
          if (error.status === 401) {
            this.alertService.showAlert(
                this.alertService.typeDanger,
                this.alertService.loginError
            );
        }
          return throwError(error);
        })
      );
  }

  signinToken(token) {
    this.signingIn = true;
    return this.http.post(`${environment.apiURL}/login-token`, { token }).pipe(
      map((res: any) => {
        let token = res.token;
        this.setToken(token, !!token);
        this.getProfile(token).subscribe((e: any) => {
          this.setUserAndToken(token, e.data, !!token);
          this.signingIn = false;
          return token;
        });
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getProfile(token = null, filters: any = {}) {
    this.signingIn = true;
    let headers;

    if (!token) {
      token = this.getJwtToken();
    }

    let url = `${environment.apiURL}/profile`;
    if (filters.includes) {
      url += `?includes=${filters.includes}`;
    }

    headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.http.get(url, headers)
    .pipe(
      tap((response: any) => {
        if (response.data) {
          this.setUser(response.data)
        }
      })
    )
    .pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public updateProfile(data) {
    let headers = (this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getJwtToken()}`,
      }),
    });
    this.signingIn = true;
    return this.http
      .post(`${environment.apiURL}/self-update`, data, headers)
      .pipe(
        tap((a) => console.log(a)),

        catchError((error) => {
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  signout() {
    this.setUserAndToken(null, null, false);
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.setUser(user);
    this.ls.setItem(this.JWT_TOKEN, token);
  }

  setUser(user: User) {
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.APP_USER, user);
  }

  setToken(token: String, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.ls.setItem(this.JWT_TOKEN, token);
  }
}
