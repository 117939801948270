import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { CourseSubscription } from "app/shared/models/course-subscription";

@Component({
  selector: "app-tab-subscription-course",
  templateUrl: "./tab-subscription-course.component.html",
  styleUrls: ["./tab-subscription-course.component.scss"],
})
export class TabSubscriptionCourseComponent implements OnInit {
  @Input() subscriptions: CourseSubscription[] = [];
  @Output() changePage = new EventEmitter<any>();

  public per_page: number = 20;
  public page: number = 1;
  public pageSizeOptions = [20, 50, 100];

  ngOnInit(): void {
  }

  handlePage(e: PageEvent) {
    this.per_page = e.pageSize;
    this.page = e.pageIndex + 1;
    
    this.changePage.emit({
      per_page: this.per_page,
      page: this.page,
    });
  }

  get length() {
    return this.subscriptions.length;
  }
}
