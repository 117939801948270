<aside class="responsive-banner first">
  <div class="container-envelope">
    <svg class="cirle-a" height="160" width="160">
      <circle cx="80" cy="80" r="80" />
    </svg>
    <svg class="cirle-b" height="60" width="60">
      <circle cx="30" cy="30" r="30" />
    </svg>
    <svg class="cirle-c" height="600" width="600">
      <circle cx="300" cy="300" r="300" />
    </svg>
    <svg class="cirle-d" height="60" width="60">
      <circle cx="30" cy="30" r="30" />
    </svg>
    <img [src]="image" />
    <div class="col-xs-12">
      <p>{{ title }}</p>
      <h5 *ngIf="!isCourse">{{classification}}</h5>

      <h5 *ngIf="group?.code">Grupo {{group?.code}}</h5>
    </div>
  </div>
</aside>