import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageService } from "../../services/message.service";
import { AlertService } from "../../services/alert.service";
import { User } from "app/shared/models/user.model";
import { UserService } from "app/shared/services/user.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";

@Component({
  selector: "new-message-modal",
  templateUrl: "new-message-modal.component.html",
})
export class NewMessageModalComponent implements OnInit {
  public form: FormGroup;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;
  public users: User[] = [];
  private course_id: number = null;
  public userSelected: User;
  private authUser: User;
  public to_support: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { course_id: number; user: User | null , to_support: boolean | null},
    public dialogRef: MatDialogRef<NewMessageModalComponent>,
    private messageService: MessageService,
    private alertService: AlertService,
    private userService: UserService,
    public jwtAuth: JwtAuthService
  ) {}

  ngOnInit(): void {
    this.course_id = this.data?.course_id;
    if (this.data.user) {
      this.handleClick(this.data.user);
    }
    this.to_support = this.data.to_support ? this.data.to_support : false;
    this.authUser = this.jwtAuth.getUser();

    this.form = new FormGroup({
      content: new FormControl("", [
        Validators.required,
        Validators.maxLength(10000),
      ]),
    });
  }

  get ableSearch() {
    return !this.data.user;
  }

  get f() {
    return this.form.controls;
  }

  get content() {
    return this.form.get("content");
  }

  textChange($event) {
    let filter = {
      name: $event.target.value ? $event.target.value.toLowerCase() : "",
      course_id: this.course_id,
      without_pagination: true,
    };

    if (!filter.course_id) {
      delete filter.course_id;
    }

    this.users = [];
    this.userService
      .getUsers(filter)
      .subscribe((response: { data: User[] }) => {
        this.users = response.data;

        let index = this.users.findIndex((user) => {
          return user.id === this.authUser.id;
        });

        if (index > -1) {
          this.users.splice(index, 1);
        }
      });
  }

  submit() {
    if (this.form.invalid) {
      this.content.markAsDirty();
      this.content.markAsTouched();
      return;
    }

    let user_ids = this.to_support ? [] : [this.userSelected.id];

    this.messageService
      .storeMessage({
        content: this.content.value,
        user_ids: user_ids,
        uuid: null,
        course_id: this.course_id,
        to_support: this.to_support
      })
      .subscribe((response) => {
        this.alertService.showAlert(
          this.alertService.typeSuccess,
          this.alertService.messageSendSuccessfully
        );
        this.form.reset();
        this.dialogRef.close(true);
      });
  }

  handleClick(user: User) {
    this.userSelected = user;
  }

  getName() {
    if (this.to_support) {
      return "Soporte de plataforma";
    }
    if (!this.userSelected) {
      return `Seleccione un usuario`;
    }
    if (this.userSelected.name) {
      return this.userSelected.name;
    }

    return `${this.userSelected.first_name} ${this.userSelected.last_name}`;
  }
}
