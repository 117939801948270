<div class="container">
    <!-- <app-history-messages (activeUuid)="changeUuid($event)" [isOpen]="isOpen"></app-history-messages> -->
    <div class="content">
        <header>
            <button *ngIf="hasCourse" mat-stroked-button class="btn-back ml-10" (click)="backToCourse()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <!-- <mat-icon color="" class="text-60 toggle-icon" [ngStyle]="{height: '60px', width: '60px'}"
                (click)="toggleHistory()">
                short_text</mat-icon> -->

            <mat-chip-list #chipList aria-label="Integrantes de la conversación" class="ml-auto"
                *ngIf="users.length > 0">
                <mat-chip class="participan-tag" *ngFor="let user of users" [title]="user.name">
                    <span>{{user.name}}</span>
                </mat-chip>
            </mat-chip-list>
        </header>
        <div class="message-wrap">
            <div class="message-list" [ngClass]="{'me' : message.own}" *ngFor="let message of messages">
                <div class="send-by">
                    {{ getSendBy(message) }}
                </div>
                <div class="msg">
                    <mat-card class="m-0" [innerHTML]="getContent (message)">
                    </mat-card>
                </div>
                <div class="time" [title]="getTime(message)">{{ getTime(message) }}</div>
            </div>
        </div>

        <form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="canByReply">
            <div class="messageBox">
                <div class="textA">
                    <mat-form-field class="w-100">
                        <mat-label>Mensaje</mat-label>
                        <textarea placeholder="Agregue un mensaje..." matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" class="w-100"
                            formControlName="content" (onContentChanged)="textChanged($event)"></textarea>
                        

                        <mat-error *ngIf="content.hasError('required')">
                            Complete su mensaje
                        </mat-error>
                        <mat-error *ngIf="content.hasError('maxLength')">
                            El contenido no puede tener más de 10,000 caracteres
                        </mat-error>
                    </mat-form-field>
                    <small>Cantidad de caracteres ({{lengthMax}}) : {{lengthQuill}}</small>

                </div>
                <button type="submit" id="send" class="button-s1" tooltip="Send" flow="left"><span
                        class="material-icons headerIcon">send</span></button>
            </div>
            <!-- </div> -->
        </form>

    </div>
</div>