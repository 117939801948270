<form [formGroup]="form" (ngSubmit)="submit()">
  <h3 mat-dialog-title>Para: <strong>{{ getName() }}</strong></h3>

  <form class="example-form" *ngIf="ableSearch && !to_support">
    <mat-form-field class="example-full-width" appearance="fill" fxFlex="100" >
      <mat-label>{{ userSelected ? "Cambiar usuario" : "Buscar usuario" }}</mat-label>
      <input class="w-100" placeholder="Buscar.." matInput aria-label="State" [matAutocomplete]="auto"
        (keyup)="textChange($event)" type="text" name="search_user" id="search_user">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let user of users" (click)="handleClick(user)">
          <!-- <img class="example-option-img" aria-hidden [src]="state.flag" height="25"> -->
          <span>{{user.name}}</span> |
          <small> {{user.email}}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <!-- <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip *ngFor="let user of usersSelected" [selectable]="selectable" [removable]="removable"
      (removed)="remove(user)">
      <span>{{user.name}}</span>
      <mat-icon matChipRemove (click)="removeUser">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list> -->

  <mat-dialog-content class="mat-typography">
    <mat-form-field class="w-100">
      <mat-label>Mensaje</mat-label>
      <textarea placeholder="Agregue un mensaje..." matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="3" class="w-100" formControlName="content"></textarea>
      <mat-error *ngIf="content.hasError('required')">
        Complete su mensaje
      </mat-error>
      <mat-error *ngIf="content.hasError('maxLength')">
        El contenido no puede tener más de 10,000 caracteres
      </mat-error>
    </mat-form-field>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="mr-auto">Cerrar</button>
    <button mat-stroked-button color="success" type="submit">Enviar</button>
  </mat-dialog-actions>
</form>