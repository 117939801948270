import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Payment } from "../models/payment.model";
import { Feedback } from "../models/feedback.model";

@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar
  ) {}

  storeFeedback(data: {
    course_id: number;
    rating: number;
    comment: string;
  }): Observable<{ data: Feedback[] }> {
    return this.http
      .post<any>(`${environment.apiURL}/feedback`, data, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.loader.close();
          if (error.status === 422) {
            this.snack.open(error.error.message, "OK", {
              duration: 2000,
              horizontalPosition: "center",
              verticalPosition: "top",
            });
          } else {
            this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
              duration: 2000,
              horizontalPosition: "center",
              verticalPosition: "top",
            });
          }

          return throwError(error);
        })
      );
  }

  getMyFeedback(subscription_id: number): Observable<{ data: Feedback }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/my-feedback/${subscription_id}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
}
