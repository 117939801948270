import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RelativeTimePipe } from "./relative-time.pipe";
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from "./get-value-by-key.pipe";
import { MemberSubtypePipe } from "./member-subype.pipe";
import { CurrencyPipe } from "./currency.pipe";
import { RemoveHtmlPipe } from "./remove-html.pipe";

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  MemberSubtypePipe,
  CurrencyPipe,
  RemoveHtmlPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes,
})
export class SharedPipesModule {}
