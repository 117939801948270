import { Observable } from "rxjs";
import {
  MatDialogRef,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { Injectable } from "@angular/core";

import { AppComfirmComponent } from "./app-confirm.component";

interface confirmData {
  title?: string;
  message?: string;
}

@Injectable()
export class AppConfirmService {
  constructor(private dialog: MatDialog) {}

  public confirm(data: confirmData = {}): Observable<boolean> {
    data.title = data.title || "Confirmación";
    data.message = data.message || "¿Está seguro?";
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: "380px",
      disableClose: true,
      data: { title: data.title, message: data.message },
    });
    return dialogRef.afterClosed();
  }

  popupCenter(url: string, title: string, w: number, h: number) {
    // Fixes dual-screen position                             Most browsers      Firefox
    let dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    let dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    let width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    let height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    let systemZoom = width / window.screen.availWidth;
    let left = (width - w) / 2 / systemZoom + dualScreenLeft;
    let top = (height - h) / 2 / systemZoom + dualScreenTop;
    let newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );
  }
}
