import { Component, Input, OnInit } from "@angular/core";
import { LearningPackage } from "app/shared/models/learning-package.model";

@Component({
  selector: "app-card-learning-package",
  templateUrl: "./card-learning-package.component.html",
  styleUrls: ["./card-learning-package.component.scss"],
})
export class CardLearningPackageComponent implements OnInit {
  @Input() learningPackage: LearningPackage;
  @Input() isFinished: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  get goToDetail() {
    return `/courses/${this.learningPackage?.id}/learning_package`;
  }

  get goToDetailText() {
    if (this.learningPackage.type === "path") {
      return "Ir a la Ruta";
    }
    return "Ir al Paquete";
  }
}
