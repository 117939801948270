import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-banner-course",
  templateUrl: "./banner-course.component.html",
  styleUrls: ["./banner-course.component.scss"],
})
export class BannerCourseComponent {
  @Input() subscription: any;

  constructor(private router: Router) {}

  goContent() {
    this.router.navigateByUrl(`/courses/${this.course.id}/topics`);
  }

  get course() {
    return this.subscription?.course;
  }

  get group() {
    return this.subscription?.group;
  }

  get learning_package() {
    return this.subscription?.learning_package;
  }

  get classification() {
    return !this.isCourse &&
      this.learning_package &&
      this.learning_package.type === "path"
      ? "Ruta de aprendizaje"
      : "Paquete de cursos";
  }

  get title() {
    return this.isCourse ? this.course?.title : this.learning_package?.title;
  }

  get isCourse() {
    return this.subscription?.classification === "course";
  }

  get image(): string {
    let main_image = this.isCourse
      ? this.course?.main_image
      : this.learning_package?.main_image;
    if (main_image) {
      let original_url = main_image.original_url;
      if (
        original_url.indexOf("http://") !== 0 &&
        original_url.indexOf("https://") !== 0
      ) {
        original_url = `http://${original_url}`;
      }
      return original_url;
    }

    return `assets/images/tech.jpg`;
  }
}
