import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Payment } from "../models/payment.model";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private alertService: AlertService
  ) {}

  getPayments(meta: {
    per_page: number;
    page: number;
  }): Observable<{ data: Payment[]; meta: any }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/payments?per_page=${meta.per_page}&page=${meta.page}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  showPayment(payment_id: number) {
    let url = `${environment.apiURL}/payments/${payment_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  getPaymentsVouchers(payment_code: string) {
    let url = `${environment.apiURL}/payments-vouchers?payment_code=${payment_code}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  storePaymentInscription(data: { payment_id: number }): Observable<{
    payment: Payment;
  }> {
    let url = `${environment.apiURL}/payments/inscriptions`;

    return this.http.post<any>(url, data, this.httpOptions).pipe(
      catchError((error) => {
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }


  storePaymentCertificateOrRecord(subscription_id: number, type: string ): Observable<{
    payment: Payment;
  }> {
    let url = `${environment.apiURL}/subscriptions/${subscription_id}/payments/${type}`;

    return this.http.post<any>(url, [], this.httpOptions).pipe(
      catchError((error) => {
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  deletePayment(payment_id: number) {
    return this.http.delete<any>(`${environment.apiURL}/payment-file/${payment_id}`, this.httpOptions).pipe(
      catchError((error) => {
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }
}
