import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  ViewChildren,
  Output,
  Renderer2,
} from "@angular/core";
import { ThemeService } from "../../services/theme.service";
import { LayoutService } from "../../services/layout.service";
import { TranslateService } from "@ngx-translate/core";
import { JwtAuthService } from "../../services/auth/jwt-auth.service";
import { EgretNotifications2Component } from "../egret-notifications2/egret-notifications2.component";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-header-side",
  templateUrl: "./header-side.template.html",
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  @ViewChildren(EgretNotifications2Component) noti;
  public availableLangs = [
    {
      name: "EN",
      code: "en",
      flag: "flag-icon-us",
    },
    {
      name: "ES",
      code: "es",
      flag: "flag-icon-es",
    },
  ];
  currentLang = this.availableLangs[0];

  public egretThemes;
  public layoutConf: any;
  public displayImage:string = ''

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private router: Router
  ) {}
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);

    let userStorage = localStorage.getItem("USER");
    this.displayImage =
    userStorage && JSON.parse(userStorage)?.avatar
      ? JSON.parse(userStorage)?.avatar?.original_url
      : "assets/images/face-7.jpg";
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    this.layoutConf = this.layout.layoutConf;

    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }

    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }

  toggleCollapse() {
    console.log("toogle: is currently ", this.layoutConf.sidebarStyle);
    if (this.layoutConf.sidebarStyle === "compact") {
      console.log("toogle: is currently compact");
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: "full",
          sidebarCompactToggle: false,
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: "compact",
        sidebarCompactToggle: true,
      },
      { transitionClass: true }
    );
  }

  onSearch(e) {
    //   console.log(e)
  }
}
