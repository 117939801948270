import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { CourseService } from "app/shared/services/course.service";
import { Observable } from "rxjs";
import { Course } from '../../shared/models/course.model';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class DashboardComponent implements OnInit {
  public courses: Course[] = [];

  constructor(
    private jwAuth: JwtAuthService,
    public courseService: CourseService
  ) {
    this.courseService
      .getCoursesInProgress()
      .subscribe((response: { data: Course[] }) => {
        this.courses = response.data;
      });
    // this.jwAuth.getProfile().subscribe((res: any) => {
    //   this.courses = this.courseService.getCourses(3, 0);
    // });
  }

  ngOnInit(): void {}
}
