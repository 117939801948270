import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { environment } from "environments/environment";
import { map, catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AppLoaderService } from "./app-loader/app-loader.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Course } from "../models/course.model";
import { CourseSubscription } from "../models/course-subscription";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };

  httpOptionsImage = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private alertService: AlertService
  ) {}

  getSubscriptionByCourse(
    course_id: number
  ): Observable<{ data: CourseSubscription }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/courses/${course_id}/subscriptions?includes=learning_package`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getSubscriptionByLearningPackage(
    learning_package_id: number
  ): Observable<{ data: CourseSubscription }> {
    console.log("getSubscriptionByLearningPackage");
    return this.http
      .get<any>(
        `${environment.apiURL}/learning-packages/${learning_package_id}/subscriptions?includes=learning_package,learning_package_items,course,course_ids_by_learning_package`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.loader.close();
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getFiles(subscription_id: number): Observable<{ data: any }> {
    let url = `${environment.apiURL}/my-files/${subscription_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  checkAvailableFiles(subscription_id: number): Observable<{ data: any }> {
    let url = `${environment.apiURL}/check-available-files/${subscription_id}`;

    return this.http.get<any>(url, this.httpOptions);
  }

  downloadFileByHash(
    id: number
  ): Observable<{ data: { file: string; filename: string } }> {
    let url = `${environment.apiURL}/download-file/${id}/hash`;

    this.loader.open();
    return this.http.get<any>(url, this.httpOptions).pipe(
      tap((_) => this.loader.close()),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  showGuestFile(data: { name: string }): Observable<{ data: any }> {
    let url = `${environment.apiURL}/show-guest-file`;

    this.loader.open();
    return this.http.post<any>(url, data, this.httpOptions).pipe(
      tap((_) => this.loader.close()),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  downloadGuestFile(data: {
    name: string;
    hash: string;
  }): Observable<{ data: { file: string; filename: string; data: any } }> {
    let url = `${environment.apiURL}/download-guest-file`;

    return this.http.post<any>(url, data, this.httpOptions);
  }

  generateMyCertificate(subscription_id: number): Observable<any> {
    let url = `${environment.apiURL}/my-certificates/${subscription_id}`;

    this.loader.open();
    return this.http.post<any>(url, [], this.httpOptions).pipe(
      tap((_) => this.loader.close()),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  generateMyRecord(subscription_id: number): Observable<any> {
    let url = `${environment.apiURL}/my-records/${subscription_id}`;

    this.loader.open();
    return this.http.post<any>(url, [], this.httpOptions).pipe(
      tap((_) => this.loader.close()),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  seeSubscription(course_id: number, interval: number = null) {
    let url = `${environment.apiURL}/courses/${course_id}/subscriptions-see`;

    return this.http.post<any>(url, { interval }, this.httpOptions);
  }

  generateCertificatePayment(subscription_id: number) {
    let url = `${environment.apiURL}/subscriptions/${subscription_id}/payments/subscription_certificates`;

    this.loader.open();
    return this.http.post<any>(url, {}, this.httpOptions).pipe(
      tap((_) => this.loader.close()),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  generateRecordPayment(subscription_id: number) {
    let url = `${environment.apiURL}/subscriptions/${subscription_id}/payments/subscription_records`;

    this.loader.open();
    return this.http.post<any>(url, {}, this.httpOptions).pipe(
      tap((_) => this.loader.close()),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  redeemCertificate(subscription_id: number) {
    let url = `${environment.apiURL}/subscriptions/${subscription_id}/redeem-certificate`;

    this.loader.open();
    return this.http.put<any>(url, {}, this.httpOptions).pipe(
      tap((_) => this.loader.close()),
      catchError((error) => {
        this.loader.close();
        this.alertService.showErrors(error);
        return throwError(error);
      })
    );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
}
