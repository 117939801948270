import { environment } from "environments/environment";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Payment } from "app/shared/models/payment.model";
import { PaymentService } from "app/shared/services/payment.service";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";

@Component({
  selector: "detail-payment-modal",
  templateUrl: "detail-payment-modal.component.html",
})
export class DetailPaymentModalComponent implements OnInit {
  public form: FormGroup;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;
  private payment_code: string = null;
  public waitingPayment: Payment = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DetailPaymentModalComponent>,
    private paymentService: PaymentService,
    private confirmService: AppConfirmService
  ) {}

  private model_type = null;

  ngOnInit(): void {
    this.payment_code = null;

    this.data.items.forEach((item) => {
      if (
        [
          "subscription_certificates",
          "subscription_records",
          "packages",
          "paths",
          "plans",
          "subscriptions",
        ].includes(item.model_type)
      ) {
        this.payment_code = this.data.payment_code;
        this.model_type = item.model_type;
      }
    });
  }

  isCertificateOrRecord() {
    return ["subscription_certificates", "subscription_records"].includes(
      this.model_type
    );
  }

  get hasExternalCode() {
    return this.payment_code && this.data.status === "pending";
  }

  get showPaymentButton() {
    return this.data.status !== "confirmed";
  }

  get hasQuota() {
    return (
      this.data.items.findIndex((item) => item.model_type === "subscriptions") >
        -1 && this.data.status === "pending"
    );
  }

  storePaymentSubscription(payment: Payment) {
    let data = {
      payment_id: payment.id,
    };

    this.paymentService.storePaymentInscription(data).subscribe((response) => {
      if (response.payment && response.payment.payment_code) {
        this.availableCheckout(response.payment, response.payment.payment_code);
      }
    });
  }

  storeCertificateOrRecord(payment: Payment) {
    this.paymentService
      .storePaymentCertificateOrRecord(
        payment.items[0].model_id,
        payment.items[0].model_type
      )
      .subscribe((response) => {
        if (response.payment && response.payment.payment_code) {
          this.availableCheckout(
            response.payment,
            response.payment.payment_code
          );
        }
      });
  }

  availableCheckout(payment, code) {
    this.waitingPayment = payment;

    this.goExternalPayment(code);
    this.checkPaymentStatus();
  }

  checkPaymentStatus() {
    if (!this.waitingPayment) {
      return;
    }

    this.paymentService
      .showPayment(this.waitingPayment.id)
      .subscribe((response) => {
        if (response.data.status === "confirmed") {
          this.data = response.data;
          this.waitingPayment = null;
          this.reloadDialog();
        } else {
          setTimeout(() => {
            this.checkPaymentStatus();
          }, 3000);
        }
      });
  }

  goExternalPayment(code: string = null) {
    if (!code) {
      if (!this.payment_code) {
        if (this.isCertificateOrRecord) {
          return this.storeCertificateOrRecord(this.data);
        }
        return this.storePaymentSubscription(this.data);
      }
      code = this.payment_code;
    }
    let url = `${environment.campusPaymentUrl}?CodPago=${code}`;
    this.confirmService.popupCenter(url, "ulearning", 700, 600);
    // window.open(`${environment.campusPaymentUrl}?CodPago=${code}`, "_blank");
  }

  deletePayment() {
    this.confirmService
      .confirm({ message: `¿Desea eliminar el registro` })
      .subscribe((response) => {
        if (response) {
          this.paymentService
            .deletePayment(this.data.id)
            .subscribe((response) => {
              this.dialogRef.close({
                reload_vouchers: false,
                reload_all: true,
              });
            });
        }
      });
  }

  reloadDialog() {
    this.dialogRef.close({ reload_vouchers: true, reload_all: false });
  }
}
