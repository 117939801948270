export const config = {
  apiUrlLocal: "http://ulearning-api.net/api",
  apiUrlDev: "https://sandbox.api.ulearning.com.pe/api",
  apiUrlProd: "https://api.ulearning.com.pe/api",
  campusPaymentUrl:
  "https://campus.uss.edu.pe/29_NewInsVirt/frmInsExtPago.aspx",
  authRoles: {
    sa: ["SA"], // Only Super Admin has access
    admin: ["SA", "Admin"], // Only SA & Admin has access
    editor: ["SA", "Admin", "Editor"], // Only SA & Admin & Editor has access
    user: ["SA", "Admin", "Editor", "User"], // Only SA & Admin & Editor & User has access
    guest: ["SA", "Admin", "Editor", "User", "Guest"], // Everyone has access
  },
};
