<div class="header-in-course-detail d-flex">
    <h3>
        <!-- <button mat-stroked-button type="button" (click)="goHome()">
            <mat-icon>home</mat-icon>
        </button> -->
        <a (click)="goDetail()">{{ data?.title }}</a>
    </h3>

    <div class="d-flex ml-auto" *ngIf="showNavegation">
        <button mat-stroked-button type="button" class="ml-auto" (click)="handlePrevious()" *ngIf="previousEnabled">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button mat-stroked-button type="button" class="mr-1" (click)="handleNext()" *ngIf="nextEnabled">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>