<div class="sidenav-hold">
  <div class="icon-menu mb-16" *ngIf="hasIconTypeMenuItem">
    <!-- Icon menu separator -->
    <div class="mb-16 nav-item-sep">
      <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
      <span class="text-muted icon-menu-title">{{iconTypeMenuTitle}}</span>
    </div>
    <!-- Icon menu items -->
    <div class="icon-menu-item" *ngFor="let item of menuItems">
      <button *ngIf="!item.disabled && item.type === 'icon'" mat-stroked-button [matTooltip]="item.tooltip"
        routerLink="/{{item.state}}" routerLinkActive="mat-bg-primary">
        <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
        <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
      </button>
    </div>
  </div>

  <ul appDropdown class="sidenav">
    <li>
      <div class="px-16">
        <div class="nav-item-sep">
          <mat-divider></mat-divider>
          <span class="text-muted no-pointer">Porcentaje de avance</span>
          <span class="text-muted" *ngIf="percentage || percentage === 0"><small>{{ percentage }} %</small></span>
          <mat-progress-bar mode="determinate" [value]="percentage" class="mb-3" color="green">
          </mat-progress-bar>
        </div>
        <div class="nav-item-sep" *ngIf="course?.evaluationStats">
          <mat-divider></mat-divider>

          <span class="text-muted no-pointer">
            {{ course?.evaluationStats?.finished_total }} / {{ course?.evaluationStats?.evaluation_total }} evaluaciones
            completas
          </span>
        </div>
      </div>
    </li>
    <li *ngFor="let item of menuItems" appDropdownLink routerLinkActive="open">
      <div class="nav-item-sep" *ngIf="item.type === 'separator'">
        <mat-divider></mat-divider>
        <span class="text-muted">{{item.name | translate}}</span>
      </div>
      <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
        <a routerLink="/{{item.state}}" [queryParams]="item.queryParams" appDropdownToggle matRipple
          *ngIf="item.type === 'link'">
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges">{{ badge.value }}</span>
        </a>
        <a [href]="item.state" appDropdownToggle matRipple *ngIf="item.type === 'extLink'" target="_blank">
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges">{{ badge.value }}</span>
        </a>

        <!-- DropDown -->
        <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple>
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges">{{ badge.value }}</span>
          <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
        </a>

        <!-- ExternalLink -->
        <a *ngIf="item.type === 'externalLink'" appDropdownToggle matRipple (click)="handleExternalLink(item)">
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges">{{ badge.value }}</span>
        </a>

        <!-- LEVEL 2 -->
        <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
          <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">

            <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" [queryParams]="item.queryParams"
              appDropdownToggle matRipple *ngIf="item.type === 'link'">
              <mat-icon *ngIf="itemLvL2.icon" class="sidenav-mat-icon">{{itemLvL2.icon}}</mat-icon>
              <mat-icon *ngIf="itemLvL2.svgIcon" [svgIcon]="itemLvL2.svgIcon" class="svgIcon"></mat-icon>
              <span class="itemLvL2-name lvl1">{{itemLvL2.name | translate}}</span>
              <span fxFlex></span>
              <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                *ngFor="let badge of itemLvL2.badges">{{ badge.value }}</span>
            </a>

            <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" [queryParams]="item.queryParams"
              appDropdownToggle *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
              <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
              <span fxFlex></span>
            </a>

            <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" [queryParams]="item.queryParams"
              class="drop-aqui" *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
              <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <!-- LEVEL 3 -->
            <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
              <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                <a routerLink="{{itemLvL3.state}}" [queryParams]="item.queryParams" appDropdownToggle matRipple>
                  <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                </a>
              </li>
            </ul>

          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>