import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Conversation } from "../models/conversation.model";
import { Message } from "../models/messages.model";
import { LocalStoreService } from "./local-store.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  JWT_TOKEN = "JWT_TOKEN";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getJwtToken()}`,
    }),
  };
  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private alertService: AlertService
  ) {}

  getUuids(): Observable<{ data: { uuids: string } }> {
    return this.http
      .get<any>(`${environment.apiURL}/messages-uuids`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getLastsMessages(uuids: string): Observable<{ data: Message[] }> {
    return this.http
      .get<any>(
        `${environment.apiURL}/messages-lasts?uuids=${uuids}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getHistory(uuid: string): Observable<{ data: any }> {
    return this.http
      .get<any>(`${environment.apiURL}/messages/${uuid}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  storeMessage(data: {
    content: string;
    user_ids: number[];
    uuid: string | null;
    course_id: number;
    to_support: boolean;
  }): Observable<{ data: any }> {
    this.loader.open();

    if (!data.uuid) {
      delete data.uuid;
    }

    return this.http
      .post<any>(`${environment.apiURL}/messages`, data, this.httpOptions)
      .pipe(
        tap((_) => this.loader.close()),
        catchError((error) => {
          this.loader.close();
          this.alertService.showErrors(error);
          return throwError(error);
        })
      );
  }

  storeForumReply(
    parent_id: number,
    data: {
      subject: string;
      content: string;
    }
  ): Observable<{ data: any }> {
    return this.http
      .post<any>(
        `${environment.apiURL}/topics-forums/${parent_id}/reply`,
        data,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          this.snack.open("Ha ocurrido un error, intente nuevamente", "OK", {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          return throwError(error);
        })
      );
  }

  getConversations(
    course_id: number | null,
    data: {
      per_page: number;
      page: number;
    }
  ): Observable<{ data: Conversation[]; meta: any }> {
    let urlParams = `per_page=${data.per_page}&page=${data.page}&includes=first_message`
    if (course_id) {
      urlParams += `&course_id=${course_id}`
    }  else {
      urlParams += `&to_support=true`
    }

    return this.http
      .get<any>(
        `${environment.apiURL}/conversations?${urlParams}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getMessages(
    course_id: number,
    includes: string,
    data: {
      per_page: number;
      page: number;
    }
  ) {
    let urlParams = `per_page=${data.per_page}&page=${data.page}&includes=${includes}`
    if (course_id) {
      urlParams += `&course_id=${course_id}`
    }

    return this.http
      .get<any>(
        `${environment.apiURL}/messages?${urlParams}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
}
