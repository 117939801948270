<!-- <mat-card>
    <img class="pointer" (click)="goConversation(conversation)" mat-card-image [src]="getImage(conversation)" alt="Imagen de curso">
    <mat-card-actions class="ml-auto">
        
        <mat-card-title>
            <mat-progress-bar *ngIf="conversation.percentage" mode="determinate" [value]="conversation.percentage" class="mb-3"></mat-progress-bar>

            <a (click)="goConversation(conversation)">
                <h3>{{conversation.title}}</h3>
            </a>
        </mat-card-title>
    </mat-card-actions>
</mat-card> -->
<div class="conversation" (click)="goConversation(conversation)" *ngIf="conversation?.firstMessage">
    <!-- <div class="conversation-preview">
        <img class="pointer" [src]="getImage(conversation)" alt="Imagen de curso">
    </div> -->
    <div class="conversation-info">
        <div class="category-container">
            <mat-chip class="category-chip" cdkDrag *ngFor="let receiver of receiverUsers.slice(0,2)">
                {{receiver?.name}}
            </mat-chip>
            <mat-chip class="category-chip" cdkDrag *ngIf="receiverUsers.length > 2">
                {{receiverUsers.length - 2}} más
            </mat-chip>
        </div>
        <!-- <h2>{{ receiver }}</h2> -->

        <div class="progress-container w-100">
            <div class="progress-label">
                {{ content }}
                <!-- <span *ngIf="conversation.group?.date_start">
                    Inicio: {{ conversation.group?.date_start }}
                </span>
                <span class="ml-auto">
                    {{ conversation.percentage }} %
                </span> -->
            </div>
            <div class="progress-bar">
                <span class="time"> {{ getTime() }} </span>
            </div>
        </div>

    </div>
</div>