import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedMaterialModule } from "../shared-material.module";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SearchModule } from "../search/search.module";
import { SharedPipesModule } from "../pipes/shared-pipes.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedDirectivesModule } from "../directives/shared-directives.module";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./header-side/header-side.component";
import { SidebarSideComponent } from "./sidebar-side/sidebar-side.component";

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./header-top/header-top.component";
import { SidebarTopComponent } from "./sidebar-top/sidebar-top.component";

// ONLY FOR DEMO
import { CustomizerComponent } from "./customizer/customizer.component";

// ALWAYS REQUIRED
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { AppComfirmComponent } from "../services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "../services/app-loader/app-loader.component";
import { ButtonLoadingComponent } from "./button-loading/button-loading.component";
import {
  EgretSidebarComponent,
  EgretSidebarTogglerDirective,
} from "./egret-sidebar/egret-sidebar.component";
import { BottomSheetShareComponent } from "./bottom-sheet-share/bottom-sheet-share.component";
import { EgretExampleViewerComponent } from "./example-viewer/example-viewer.component";
import { EgretExampleViewerTemplateComponent } from "./example-viewer-template/example-viewer-template.component";
import { EgretNotifications2Component } from "./egret-notifications2/egret-notifications2.component";
import { CourseComponent } from "./course/course.component";
import { HeaderInCourseComponent } from "app/shared/components/header-in-course/header-in-course.component";
import { BannerCourseComponent } from "./banner-course/banner-course.component";
import { TeacherCardComponent } from "./teacher-card/teacher-card.component";
import { NewMessageModalComponent } from "../modals/new-message-modal/new-message-modal.component";
import { DetailPaymentModalComponent } from "../modals/detail-payment-modal/detail-payment-modal.component";
import { EditFeedbackModalComponent } from "../modals/edit-feedback-modal/edit-feedback-modal.component";
import { StarRatingModule } from "angular-star-rating";
import { TabSubscriptionCourseComponent } from "./tab-subscription-course/tab-subscription-course.component";
import { CourseLayoutComponent } from "./layouts/course-layout/course-layout.component";
import { SidebarCourseSideComponent } from "./sidebar-course-side/sidebar-course-side.component";
import { SidenavCourseComponent } from "./sidenav-course/sidenav-course.component";
import { ConversationComponent } from "./conversation/conversation.component";
import { CardLearningPackageComponent } from "./card-learning-package/card-learning-package.component";
import { ListCardLearningPackageComponent } from "./list-card-learning-package/list-card-learning-package.component";
import { AboutCourseComponent } from "./about-course/about-course.component";
import { CertificateSectionComponent } from "./certificate-section/certificate-section.component";

const components = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidebarCourseSideComponent,
  SidenavComponent,
  SidenavCourseComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  CourseLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  EgretNotifications2Component,
  CustomizerComponent,
  ButtonLoadingComponent,
  EgretSidebarComponent,
  FooterComponent,
  EgretSidebarTogglerDirective,
  BottomSheetShareComponent,
  EgretExampleViewerComponent,
  EgretExampleViewerTemplateComponent,
  CourseComponent,
  HeaderInCourseComponent,
  BannerCourseComponent,
  TeacherCardComponent,
  NewMessageModalComponent,
  DetailPaymentModalComponent,
  EditFeedbackModalComponent,
  TabSubscriptionCourseComponent,
  ConversationComponent,
  CardLearningPackageComponent,
  ListCardLearningPackageComponent,
  AboutCourseComponent,
  CertificateSectionComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    StarRatingModule.forRoot(),
  ],
  declarations: components,
  // entryComponents: [AppComfirmComponent, AppLoaderComponent, BottomSheetShareComponent],
  exports: components,
})
export class SharedComponentsModule {}
